@font-face {
    font-family: ThmorPro;
    src: url(fonts/KdamThmorPro-Regular.ttf);
}

.homediv {
    text-align: center;
}

h3.hometitles{
    font-weight: normal;
}

#homeinfogrid {
    display: grid;
    grid-template-columns: auto auto auto;
}

#titlesdiv {
    background-color: rgb(46, 38, 38);
    position: relative;
}

#titles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(241, 225, 225);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

#titlesdiv img {
    filter: blur(2px);
    width: 100%;
}

.infogriditem {
    margin: 0 2em 0 2em;
    background-color: rgba(132, 208, 218, 0.897);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 0% 3% 3% 3%;
}

.infogriditem h1 {
    font-family: ThmorPro;
    font-size: 200%;
}

@media all and (max-width: 870px) {
    #homeinfogrid {
        display: flex;
        flex-direction: column;
    }

    .infogriditem {
        margin: 3%;
    }
}

