.soapdiv {
    text-align: center;
}

.infotext {
    padding: 0 20% 0 20%;
    text-align: center;
}

.infotext li:hover {
    background-color: transparent;
}

.infotext li {
    list-style: none;
    font-weight: normal;
}

.infotexthead {
    font-weight: bold;
}

.soapdiv img {
    max-width: 100%;
}