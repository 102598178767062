.contactcontainer {
    display: inline-block;
    margin: 0 2em 0 2em;
    background-color: rgba(132, 208, 218, 0.897);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 0% 3% 3% 3%;
}

.contactcontainer h2 {
    font-size: 200%;
}

.contactcontainer h5 {
    font-weight: normal;
    font-size: 110%;
}

.underbar {
    background-color: gray;
}