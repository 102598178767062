@font-face {
  font-family: ThmorPro;
  src: url(fonts/KdamThmorPro-Regular.ttf);
}

body {
  text-align: center;
  background-color: lightgrey;
}

li a:link {
  color: black;
  text-decoration: none; 
}


li a:visited {
  color: black;
  text-decoration: none;
}


li a:hover {
  color: white;
  text-decoration: none;
  transition: 0.3s;
}


li a:active {
  color: black;
  text-decoration: none;
}

li a {
  padding: 1.55em;
}

li:hover {
  background-color: black;
  opacity: 100%;
  transition: 0.3s;
}

#navbar {
  background-color: gray;
}

/* Styling for the navigation bar itself */
#navelements {
  display: flex;
  font-size: 20px;
  justify-content: flex-end;
  list-style: none; /* Removes the bullets from the "list" */
}

/* Styling for each navigation bar element */
.navelement {
  font-weight: bold;
  padding: 1%;
  padding: 1.5em 0 1.5em 0;
  transition: 0.3s;
}

#navbarheader {
  float: left;
  font-family: ThmorPro;
}

#navbarheader a {
  padding: 1.55em;
}

#navbarheader a:link {
  color: black;
  text-decoration: none; 
}


#navbarheader a:visited {
  color: black;
  text-decoration: none;
}

#navbarheader a:active {
  color: black;
  text-decoration: none;
}

/* Creates a verticle layout for the navbar for mobile devices*/
@media all and (max-width: 870px) {

  #navelements {
    text-align: center;
    padding: 2%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .navelement {
    text-align: center;
    margin: 5%;
    padding: 1%;
  }

}